import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
  FETCH_EMPLOYEE_CONTRACTS,
  ADD_NEW_EMPLOYEE_CONTRACT
} from "../actions.type";
// mutation types
import { SET_EMPLOYEE_CONTRACTS } from "../mutations.type";

const state = {
  errors: null,
  employeeContracts: []
};

const getters = {
  getEmployeeContracts(state) {
    return state.employeeContracts;
  }
};

const actions = {
  async [FETCH_EMPLOYEE_CONTRACTS](context, id) {
    // avoid extronuous network call if article exists
    await ApiService.get("user/contract/" + id + "/get")
      .then(({ data }) => {
        context.commit(SET_EMPLOYEE_CONTRACTS, data.data);
      });
  },

  [ADD_NEW_EMPLOYEE_CONTRACT](context, formData) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("user/contract/" + formData.userId + "/store", {
          end_date: formData.end_date,
          start_date: formData.start_date,
          designation_id: formData.designation_id
        })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  }
};

const mutations = {
  [SET_EMPLOYEE_CONTRACTS](state, data) {
    state.employeeContracts = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
