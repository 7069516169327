import Vue from "vue";
import Router from "vue-router";
import authRoute from "./auth";
import dashboardRoute from './dashboard';
import leaveRoute from './leaves';
import attendaceRoute from './attendance';
import settingRoute from './setting';
import shiftRoute from './shift';
import employeeRoute from './employee';
import commonRoute from './common';
import payrollRoute from './payroll';
Vue.use(Router);

var router =  new  Router({
  mode: "history",
  routes: [
    ...authRoute,
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/components/layouts/app"),
      children: [
        ...dashboardRoute,
        ...leaveRoute,
        ...attendaceRoute,
        ...settingRoute,
        ...shiftRoute,
        ...employeeRoute,
        ...commonRoute,
        ...payrollRoute
      ]
    },



  ]
})

export default router;
