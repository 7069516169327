export default [
    {
        name: "settings",
        path: "/settings",
        component: () => import("@/pages/setting/index.vue"),
        meta: { requiresAuth: true ,permission:'access_to_settings'},
    
    },
    {
        path: "settings/shift-type",
        name: "setting.shift-type",
        component: () => import("@/pages/admin/shift/default/index.vue"),
        meta: { requiresAuth: true }
    },

    {
        path: "settings/roles",
        name: "setting.roles",
        component: () => import("@/pages/setting/roles/index.vue"),
        meta: { requiresAuth: true }
    },
    
]