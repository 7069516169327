import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
  ADD_NEW_EMPLOYEE,
  FETCH_EMPLOYEES,
  FETCH_EMPLOYEE_DETAIL,
  UPDATE_EMPLOYEE_DETAIL,
  FETCH_ACTIVE_EMPLOYEE
} from "../actions.type";
// mutation types
import { SET_EMPLOYEES,SET_ACTIVE_EMPLOYEE } from "../mutations.type";

const state = {
  errors: null,
  employees: [],
  employeeDetail: [],
  activeEmployee:[]
};

const getters = {
  getEmployees(state) {
    return state.employees;
  },
  getActiveEmployee(state){
    return state.activeEmployee;
  }
};

const actions = {
  async [FETCH_EMPLOYEES](context) {
    // avoid extronuous network call if article exists
    if (JwtService.getToken()) {
      return await new Promise((resolve, reject) => {
        ApiService.get("employees")
          .then(({ data }) => {
            context.commit(SET_EMPLOYEES, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            reject(response.data);
            //   context.commit(SET_ERROR, response.data.errors);
          });
      });
    }
  },

  async [FETCH_ACTIVE_EMPLOYEE](context) {
    // avoid extronuous network call if article exists
    if (JwtService.getToken()) {
      return await new Promise((resolve, reject) => {
        ApiService.get("active/employees")
          .then(({ data }) => {
            context.commit(SET_ACTIVE_EMPLOYEE, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            reject(response.data);
            //   context.commit(SET_ERROR, response.data.errors);
          });
      });
    }
  },

  async [FETCH_EMPLOYEE_DETAIL](context, id) {
    if (JwtService.getToken()) {
      return await new Promise((resolve, reject) => {
        ApiService.get("employee/" + id)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [ADD_NEW_EMPLOYEE](context, formData) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("employees", {
          first_name: formData.first_name,
          middle_name: formData.middle_name,
          last_name: formData.last_name,
          email: formData.email,
          employee_id: formData.employee_id,
          gender: formData.gender,
          dob: formData.dob,
          contact_number: formData.contact_number,
          province: formData.province,
          city: formData.city,
          zip_code: formData.zip_code,
          address_line_1: formData.address_line_1,
          address_line_2: formData.address_line_2,
          pan_no: formData.pan_no,
          emergency_contact_name: formData.emergency_contact_name,
          emergency_contact_number: formData.emergency_contact_number,
          relationship: formData.relationship,
          bank_name: formData.bank_name,
          account_name: formData.account_name,
          account_number: formData.account_number,
          joining_date: formData.joining_date
        })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [UPDATE_EMPLOYEE_DETAIL](context, formData) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("employee/" + formData.id + "/update", {
          first_name: formData.first_name,
          middle_name: formData.middle_name,
          last_name: formData.last_name,
          email: formData.email,
          employee_id: formData.employee_id,
          gender: formData.gender,
          dob: formData.dob,
          contact_number: formData.contact_number,
          province: formData.province,
          city: formData.city,
          zip_code: formData.zip_code,
          address_line_1: formData.address_line_1,
          address_line_2: formData.address_line_2,
          pan_no: formData.pan_no,
          emergency_contact_name: formData.emergency_contact_name,
          emergency_contact_number: formData.emergency_contact_number,
          relationship: formData.relationship,
          bank_name: formData.bank_name,
          account_name: formData.account_name,
          account_number: formData.account_number,
          joining_date: formData.joining_date
        })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  }
};

const mutations = {
  [SET_EMPLOYEES](state, data) {
    state.employees = data;
  },
  [SET_ACTIVE_EMPLOYEE](state, data) {
    state.activeEmployee = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
