export default[
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/pages/dashboard/dashboard1.vue"),
        meta: { requiresAuth: true,permission:'access-to-dashboard' }
      },
      
      {
        path: "master",
        name: "master",
        component: () => import("@/pages/master/master.vue"),
        meta: { requiresAuth: true },
        children: [
          {
            path: "departments",
            name: "department",
            component: () => import("@/pages/master/department/index.vue"),
            meta: { requiresAuth: true }
          }
        ]
      },
  
   
     
     
]