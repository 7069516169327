import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
  ADD_NEW_SHIFT_TYPE,
  FETCH_SHIFT_TYPES,
  FETCH_SHIFT_TYPES_EDIT_DETAIL
} from "../actions.type";
// mutation types

import {
  APPEND_SHIFT_TYPES,
  SET_SHIFT_TYPES,
  SET_SHIFT_TYPE_EDIT_DETAIL
} from "../mutations.type";

const state = {
  errors: null,
  shiftTypes: [],
  shiftId: 0,
  shiftTypeEditDetail: []
};

const getters = {
  getShiftTypes(state) {
    return state.shiftTypes;
  },
  getShiftTypeId(state) {
    return state.shiftId;
  },
  getShiftTypeEditDetail(state) {
    return state.shiftTypeEditDetail;
  }
};

const actions = {
  async [FETCH_SHIFT_TYPES](context) {
    // avoid extronuous network call if article exists
    if (JwtService.getToken()) {
      return await new Promise((resolve, reject) => {
        ApiService.get("shifts-type")
          .then(({ data }) => {
            context.commit(SET_SHIFT_TYPES, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            reject(response.data);
            //   context.commit(SET_ERROR, response.data.errors);
          });
      });
    }
  },

  async [FETCH_SHIFT_TYPES_EDIT_DETAIL](context, id) {
    // avoid extronuous network call if article exists
    if (JwtService.getToken()) {
      await ApiService.get("shifts-type/" + id + "/edit")
        .then(({ data }) => {
          context.commit(SET_SHIFT_TYPE_EDIT_DETAIL, data.data);
        });
    }
  },

  [ADD_NEW_SHIFT_TYPE](context, formData) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("shifts-type", {
          shift_title: formData.shift_title,
          from_time: formData.from_time,
          to_time: formData.to_time,
          weekend: formData.weekend,
          color_code: formData.color_code
        })
          .then(({ data }) => {
            resolve(data);
            context.commit(APPEND_SHIFT_TYPES, data.data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  }
};

const mutations = {
  [SET_SHIFT_TYPES](state, data) {
    state.shiftTypes = data;
  },
  [APPEND_SHIFT_TYPES](state, data) {
    state.shiftTypes.push(data);
  },
  // [SET_SHIFT_TYPE_ID](state, id) {
  //     state.shiftId = id;
  // },
  [SET_SHIFT_TYPE_EDIT_DETAIL](state, data) {
    state.shiftTypeEditDetail = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
