import ApiService from "@/core/services/api.service";

// action types
import {
    FETCH_EMPLOYEE_SALARY_HEADS,
    FETCH_EMPLOYEE_SALARY_HEADS_DETAILS
} from "../actions.type";
// mutation types
import { GET_EMPLOYEE_SALARY_HEADS,GET_EMPLOYEE_SALARY_HEADS_DETAILS} from "../mutations.type";

const state = {
  errors: null,
  salaryHeads:[],
  salaryHeadsDetails:[]
};

const getters = {
    getSalaryHeads(state){
        return state.salaryHeads;
    },

    getSalaryHeadsDetails(state){
        return state.salaryHeadsDetails;
    }
};

const actions = {
    async [FETCH_EMPLOYEE_SALARY_HEADS](context, request) {
        // avoid extronuous network call if article exists
        await ApiService.post("payroll/salary-heads", {
          department_id: request.department_id,
          user_id: request.user_id
        })
          .then(({ data }) => {
    
            context.commit(GET_EMPLOYEE_SALARY_HEADS, { data: data.data });
          });
      },

      //payroll/4/salary-heads

      async [FETCH_EMPLOYEE_SALARY_HEADS_DETAILS](context, params) {
        // avoid extronuous network call if article exists
        await ApiService.get("payroll/"+params.user_id+"/salary-heads")
          .then(({ data }) => {
    
            context.commit(GET_EMPLOYEE_SALARY_HEADS_DETAILS, { data: data.data });
          });
      },
};

const mutations = {
  [GET_EMPLOYEE_SALARY_HEADS](state, data) {
    state.salaryHeads = data.data;
  },

  [GET_EMPLOYEE_SALARY_HEADS_DETAILS](state, data) {
    state.salaryHeadsDetails = data.data;
  },

};

export default {
  state,
  actions,
  mutations,
  getters
};
