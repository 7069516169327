import ApiService from "@/core/services/api.service";

// action types
import {
  FETCH_MONTHS,
  FETCH_YEARS,FETCH_CURRENT_YEAR_MONTH,
  FETCH_APP_MENU
} from "../actions.type";
// mutation types
import { SET_MONTHS,SET_YEARS,SET_APP_MENU} from "../mutations.type";

const state = {
  errors: null,
  months: [],
  years:[],
  appMenu:[]
};

const getters = {
  getMonths(state) {
    return state.months;
  },
  getYears(state) {
    return state.years;
  },
  getAppMenu(){
    return state.appMenu;
  }
};

const actions = {
  async [FETCH_MONTHS](context) {
    
    await ApiService.get("common/get-months")
      .then(({ data }) => {
        context.commit(SET_MONTHS, data.data);
      });
  },

  async [FETCH_YEARS](context) {
    await ApiService.get("common/get-years")
      .then(({ data }) => {
        context.commit(SET_YEARS, data.data);
      });
  },


  async [FETCH_CURRENT_YEAR_MONTH]() {
        return await new Promise((resolve, reject) => {
          ApiService.get("common/get-currrent-year-month")
            .then(({ data }) => {
              resolve(data.data);
            })
            .catch(({ response }) => {
              reject(response);
            });
        });
      
  },

  async [FETCH_APP_MENU](context) {
    await ApiService.get("app-menu")
      .then(({ data }) => {
        context.commit(SET_APP_MENU, data.data);
      });
  },



  

  

};

const mutations = {
  [SET_MONTHS](state, data) {
    state.months = data;
  },
  [SET_YEARS](state, data) {
    state.years = data;
  },
  [SET_APP_MENU](state, data) {
    state.appMenu = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
