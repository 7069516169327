export default [
    {
        name: 'assign.shift.list',
        path: '/shift/assign/list',
        component: () => import("@/pages/admin/shift/assign/index.vue"),
        meta: { requiresAuth: true,permission:'access_to_assign_shift' }
    },
    {
        name: 'assign.shift',
        path: '/shift/assign',
        component: () => import("@/pages/admin/shift/assign/assign.vue"),
        meta: { requiresAuth: true,permission:'assign_shift_to_employee' }
    }
]