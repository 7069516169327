import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import auth from "./modules/auth.module";
import config from "./modules/config.module";
import htmlClass from "./modules/htmlclass.module";
import breadCrumb from "./modules/breadcrumbs.module";
import departments from "@/core/services/store/modules/department.module";
import designation from "@/core/services/store/modules/designation.module";
import employee from "@/core/services/store/modules/employee.module";
import attendance from "@/core/services/store/modules/attendance.module";
import employeeContract from "@/core/services/store/modules/employeecontract.module";
import shiftType from "@/core/services/store/modules/shifttype.module";
import common from "@/core/services/store/modules/common.module";
import leave from "@/core/services/store/modules/leave.module";
import shift from "@/core/services/store/modules/shift.module";
import salaryHeads from "@/core/services/store/modules/salaryheads.module";
export default new Vuex.Store({
  modules: {
    auth,
    config,
    htmlClass,
    breadCrumb,
    departments,
    designation,
    employee,
    attendance,
    employeeContract,
    shiftType,
    common,
    leave,
    shift,
    salaryHeads
  }
});
