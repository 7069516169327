import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

// name is optional
Vue.use(VueLodash, lodash);
// import moment from 'moment'
// import { VERIFY_AUTH } from "./core/services/store/modules/auth.module";
// import store from "@/core/services/store";
import { CHECK_AUTH,FETCH_USER_PERMISSIONS } from "./core/services/store/actions.type";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;
// Vue 3rd party plugins
// import i18n from "@/core/plugins/vue-i18n";
import vuetify from "./core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

ApiService.init();


import vSelect from "vue-select";

Vue.component("v-select", vSelect);

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      Promise.all([store.dispatch(CHECK_AUTH)]);
      Promise.all([store.dispatch(FETCH_USER_PERMISSIONS)]);
      next();
    }else{
      next({
        path:'/login'
      });
    }
  } else {
    if (to.name == 'login') {
      if (store.getters.isAuthenticated) {
        next({
          name: "dashboard" // back to safety route //
        });
      }
    }
    next();
  }

});




new Vue({
  router,
  vuetify,
  store,

  render: h => h(App)
}).$mount("#app");
