export default [
    {
        path: '/leaves',
        name: 'leave.employee',
        component: () => import("@/pages/employee/leaves/index.vue"),
        meta: { requiresAuth: true,permission:'access_to_own_leaves_list' }
    },
    {
        path: '/leave/:id/details',
        name: 'leave.employee.details',
        component: () => import("@/pages/employee/leaves/details.vue"),
        meta: { requiresAuth: true,permission:'access_to_own_leave_details' }
    },
    {
        path: '/leave/new/request',
        name: 'leave.employee.request',
        component: () => import("@/pages/employee/leaves/add.vue"),
        meta: { requiresAuth: true,permission:'request_for_a_leaves' }
    },

    {
        path: '/leave/edit/:id/request',
        name: 'leave.employee.request.edit',
        component: () => import("@/pages/employee/leaves/edit.vue"),
        meta: { requiresAuth: true,permission:'update_own_requested_leaves' }
    },



    {
        path: '/all/leaves',
        name: 'leave.all',
        component: () => import("@/pages/admin/leaves/index.vue"),
        meta: { requiresAuth: true,permission:'access_to_all_leaves_list' }
    },


    {
        path: 'requested/leave/:id/details',
        name: 'requested.leave.employee.details',
        component: () => import("@/pages/admin/leaves/details.vue"),
        meta: { requiresAuth: true,permission:'access_to_employee_leave_details' }
    },
]