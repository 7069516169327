export default [
    {
        name: 'payroll.salary.heads',
        path: '/payroll/salary-heads',
        component: () => import("@/pages/admin/payroll/salaryhead/index.vue"),
        meta: { requiresAuth: true,permission:'access_to_assign_shift' }
    },


    {
        name: 'payroll.salary.heads.employee',
        path: '/payroll/:employee_id/salary-heads',
        component: () => import("@/pages/admin/payroll/salaryhead/edit.vue"),
        meta: { requiresAuth: true,permission:'access_to_assign_shift' }
    },
   
]