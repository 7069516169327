<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";
@import "assets/sass/style.vue";
@import "vue-select/src/scss/vue-select.scss";
</style>

<script>
import {
  OVERRIDE_LAYOUT_CONFIG,
  FETCH_MONTHS,
  FETCH_YEARS,
  FETCH_CURRENT_YEAR_MONTH
} from "@/core/services/store/actions.type";
import {
  SET_ALL_ATTENDANCE_SEARCH_YEAR,
  SET_ALL_ATTENDANCE_SEARCH_MONTH,
} from "@/core/services/store/mutations.type";
export default {
  watch: {
    $route(to) {
      var element = document.getElementById("body");
      if (to.name == "login") {
      
        element.classList.add("login-page-11231" );
      } else {
        element.classList.remove("login-page-11231");
      }
    },
  },
  mounted() {
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
    this.$store.dispatch(FETCH_MONTHS);
    this.$store.dispatch(FETCH_YEARS);
    this.$store.dispatch(FETCH_CURRENT_YEAR_MONTH).then((data) => {
      this.$store.commit(SET_ALL_ATTENDANCE_SEARCH_YEAR, data.year);
      this.$store.commit(SET_ALL_ATTENDANCE_SEARCH_MONTH, data.month);
    });

    // var element = document.getElementById("body");
    // console.log(this.$route);

    // if (this.$route.name == "login") {
    //   alert();
    //   element.classList.add("mystyle");
    // } else {
    //   element.classList.remove("mystyle");
    // }
  },
};
</script>
