//auth module
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const CHECK_AUTH = "checkAuth";
export const FETCH_USER_PERMISSIONS="fetchUserPermissions";

//config module
export const SET_LAYOUT_CONFIG = "setLayoutConfig";
export const RESET_LAYOUT_CONFIG = "resetLayoutConfig";
export const OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig";
export const OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig";

//htmlclass module
export const ADD_BODY_CLASSNAME = "addBodyClassName";
export const REMOVE_BODY_CLASSNAME = "removeBodyClassName";
export const ADD_CLASSNAME = "addClassName";

//breadcrumb module
export const APPEND_BREADCRUM = "appendBreadcrumb";

//department module
export const FETCH_DEPARTMENTS = "fetchDepartment";

//designation module
export const FETCH_DESIGNATIONS = "fetchDesignation";
export const FETCH_DESIGNATIONS_BY_DEPARTMENT_ID =
  "fetchDesignationByDepartmentId";

//employees module
export const FETCH_EMPLOYEES = "fetchEmployees";
export const ADD_NEW_EMPLOYEE = "addNewEmployee";

export const FETCH_EMPLOYEE_DETAIL = "fetchEmployeeDetail";
export const UPDATE_EMPLOYEE_DETAIL = "updateEmployeeDetail";

export const FETCH_ACTIVE_EMPLOYEE = "fetchActiveEmployee";

//empoyee contract module
export const FETCH_EMPLOYEE_CONTRACTS = "fetchEmployeeContracts";
export const ADD_NEW_EMPLOYEE_CONTRACT = "addNewEmployeeContract";

//shift type module
export const ADD_NEW_SHIFT_TYPE = "addNewShiftType";
export const FETCH_SHIFT_TYPES = "fetchShiftTypes";
export const FETCH_SHIFT_TYPES_EDIT_DETAIL = "fetchShiftTypeEditDetail";

//attendance module
export const FETCH_ATTENDANCE = "fetchAttendance";
export const FETCH_TODAY_ATTENDANCE = "fetchTodayAttendance";
export const STORE_CHECK_IN_OUT = "storeCheckInOut";
export const FETCH_ATTENDANCE_BY_DEPARTMENT =  "fetchAttendanceByDepartment";



//common module 
export const FETCH_MONTHS = "fetchMonths";
export const FETCH_YEARS = "fetchYears";
export const FETCH_CURRENT_YEAR_MONTH =  "fetchCurretnYearMonth";


//leaves
export const FETCH_EMPLOYEE_LEAVES = "fetchEmployeeLeaves"
export const FETCH_ACTIVE_LEAVE_TYPE = "fetchActiveLeaveType"
export const FETCH_EMPLOYEE_LEAVE_DETAILS= "fetchEmployeeLeaveDetails";
export const SUBMIT_NEW_LEAVE_REQUEST = "submitNewLeaveRequest";
export const EDIT_LEAVE_REQUEST =  "editLeaveRequest";
export const FETCH_LEAVES = "fetchLeaves";


//shift

export const FETCH_ASSIGN_SHIFT_LIST = "fetchAssignShiftList";
export const ASSIGN_SHIFT_TO_USER="assignShiftToUser";


export const FETCH_APP_MENU = "fetchAppMenu";


//payroll - salaryheads
export const FETCH_EMPLOYEE_SALARY_HEADS = "fetchEmployeeSalaryHeads";
export const FETCH_EMPLOYEE_SALARY_HEADS_DETAILS= "fetchEmployeeSalaryHeadsDetails";
