import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
  FETCH_ATTENDANCE,
  FETCH_TODAY_ATTENDANCE,
  STORE_CHECK_IN_OUT,
  FETCH_ATTENDANCE_BY_DEPARTMENT
} from "../actions.type";
// mutation types
import {
  SET_ATTENDANCE,
  SET_TODAY_ATTENDANCE,
  SET_ALL_ATTENDANCE_SEARCH_POPUP_BOX,
  SET_ATTENDANCE_BY_DEPARTMENT,
  SET_ALL_ATTENDANCE_DETAIL_POPUP_BOX,
  SET_ALL_ATTENDANCE_SEARCH_DEPARTMENT,
  SET_ALL_ATTENDANCE_SEARCH_YEAR,
  SET_ALL_ATTENDANCE_SEARCH_MONTH
} from "../mutations.type";

const state = {
  errors: null,
  attendances: [],
  todayAttendance: [],
  allAttendanceSearchPopupBoxFlag: true,
  allAttendancePopupDetail: {
    empData: [],
    attData: [],
    status: false
  },
  allAttendanceByDepartment: [],


  allAttendanceAttribute: {
    year: 0,
    month: 0,
    departmentId: ''
  }

};

const getters = {

  getAttendance(state) {
    return state.attendances;
  },
  getTodayAttendance(state) {
    return state.todayAttendance;
  },
  getAllAttendanceSearchPopupBoxFlag(state) {
    return state.allAttendanceSearchPopupBoxFlag;
  },
  getAllAttendanceByDepartment(state) {
    return state.allAttendanceByDepartment;
  },
  getAllAttendanceAttribute(state) {
    return state.allAttendanceAttribute;
  },
  getAllAttendancePopupDetail() {
    return state.allAttendancePopupDetail;
  }



};

const actions = {

  async [FETCH_ATTENDANCE_BY_DEPARTMENT](context, request) {
    // avoid extronuous network call if article exists
    await ApiService.post("attendance/all/department", {
      year: request.year,
      month: request.month,
      departmentId: request.departmentId
    })
      .then(({ data }) => {

        context.commit(SET_ATTENDANCE_BY_DEPARTMENT, { data: data });
      });
  },

  async [FETCH_ATTENDANCE](context, request) {
    // avoid extronuous network call if article exists
    await ApiService.post("attendance", {
      year: request.year,
      month: request.month,
      date: request.date
    })
      .then(({ data }) => {

        context.commit(SET_ATTENDANCE, { data: data });
      });
  },

  async [FETCH_TODAY_ATTENDANCE](context) {
    // avoid extronuous network call if article exists
    await ApiService.get("attendance/get-today")
      .then(({ data }) => {
        context.commit(SET_TODAY_ATTENDANCE, data.data);
      });
  },

  [STORE_CHECK_IN_OUT]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("attendance/checkInOut")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  }
};

const mutations = {
  [SET_ATTENDANCE_BY_DEPARTMENT](state, data) {
    state.allAttendanceByDepartment = data;
  },
  [SET_ALL_ATTENDANCE_SEARCH_POPUP_BOX](state, flag) {
    state.allAttendanceSearchPopupBoxFlag = flag;
  },
  [SET_ALL_ATTENDANCE_DETAIL_POPUP_BOX](state, data) {
    state.allAttendancePopupDetail.status = data.status;
    state.allAttendancePopupDetail.empData = data.empData;
    state.allAttendancePopupDetail.attData = data.attData;


  },
  [SET_ATTENDANCE](state, data) {
    if (!data.status) {
      state.attendances = data.data;
    } else {
      // const newdata = data;

      state.attendances.attendances.forEach(attendance => {
        if (attendance.date == data.data.date) {
  
          attendance.check_in = data.data.in_time;
          attendance.check_out = data.data.out_time;
          attendance.production = data.data.production;
        }
      });
    }
  },
  [SET_TODAY_ATTENDANCE](state, data) {
    state.todayAttendance = data;
  },
  [SET_ALL_ATTENDANCE_SEARCH_DEPARTMENT](state, depId) {
    state.allAttendanceAttribute.departmentId = depId;
  },
  [SET_ALL_ATTENDANCE_SEARCH_YEAR](state, year) {
    state.allAttendanceAttribute.year = year;
  },
  [SET_ALL_ATTENDANCE_SEARCH_MONTH](state, month) {
    state.allAttendanceAttribute.month = month;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
