import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
    FETCH_EMPLOYEE_LEAVES, FETCH_ACTIVE_LEAVE_TYPE, FETCH_EMPLOYEE_LEAVE_DETAILS, SUBMIT_NEW_LEAVE_REQUEST, EDIT_LEAVE_REQUEST, FETCH_LEAVES
} from "../actions.type";
// mutation types
import { SET_EMPLOYEE_LEAVES, SET_ACTIVE_LEAVE_TYPE, SET_EMPLOYEE_LEAVE_DETAILS, SET_LEAVES } from "../mutations.type";

const state = {
    errors: null,
    employeeLeaves: [],
    activeLeaveTypes: [],
    leaveDetails: [],
    leaves: [],
};

const getters = {
    getEmployeeLeaves(state) {
        return state.employeeLeaves;
    },
    getActiveLeaveTypes(state) {
        return state.activeLeaveTypes;
    },
    getLeaveDetails(state) {
        return state.leaveDetails;
    },
    getLeaves(state) {
        return state.leaves;
    }
};

const actions = {
    async [FETCH_EMPLOYEE_LEAVES](context, leaveTypeId) {

        await ApiService.get("self/leaves/" + leaveTypeId)
            .then(({ data }) => {
                context.commit(SET_EMPLOYEE_LEAVES, data.data);

            });
    },

    async [FETCH_LEAVES](context, data) {

        await ApiService.post("leaves/all", {
            leave_type_id: data.leave_type_id,
            status: data.status
        })
            .then(({ data }) => {
                context.commit(SET_LEAVES, data.data);

            });
    },


    async [FETCH_ACTIVE_LEAVE_TYPE](context) {

        await ApiService.get("leave-type/active/get")
            .then(({ data }) => {
                context.commit(SET_ACTIVE_LEAVE_TYPE, data.data);

            });
    },


    async [FETCH_EMPLOYEE_LEAVE_DETAILS](context, id) {

        if (JwtService.getToken()) {
            return new Promise((resolve, reject) => {
                ApiService.get("leave/" + id + "/details")
                    .then(({ data }) => {
                        context.commit(SET_EMPLOYEE_LEAVE_DETAILS, data.data);
                        resolve(data.data);
                    })
                    .catch(({ response }) => {
                        reject(response.data);
                    });
            });
        }
    },

    [SUBMIT_NEW_LEAVE_REQUEST](context, formData) {
        if (JwtService.getToken()) {
            return new Promise((resolve, reject) => {
                ApiService.post("leave/request", {

                    note: formData.note,
                    from_date: formData.from,
                    to_date: formData.to,
                    status: formData.status,
                    leave_type_id: formData.typeId,
                    employee_id: formData.employee_id
                })
                    .then(({ data }) => {
                        resolve(data);

                    })
                    .catch(({ response }) => {
                        reject(response.data);
                    });
            });
        }
    },
    [EDIT_LEAVE_REQUEST](context, formData) {
        if (JwtService.getToken()) {
            return new Promise((resolve, reject) => {
                ApiService.post("leave/request/edit", {
                    id: formData.id,
                    note: formData.note,
                    from_date: formData.from,
                    to_date: formData.to,
                    status: formData.status,
                    leave_type_id: formData.typeId,
                    employee_id: formData.employee_id,
                    rejected_reason:formData.rejected_reason,
                    is_paid:formData.is_paid
                })
                    .then(({ data }) => {
                        resolve(data);

                    })
                    .catch(({ response }) => {
                        reject(response.data);
                    });
            });
        }
    }


};

const mutations = {
    [SET_EMPLOYEE_LEAVES](state, data) {
        state.employeeLeaves = data;
    },
    [SET_ACTIVE_LEAVE_TYPE](state, data) {
        state.activeLeaveTypes = data;
    },
    [SET_EMPLOYEE_LEAVE_DETAILS](state, data) {
        state.leaveDetails = data;
    },
    [SET_LEAVES](state, data) {
        state.leaves = data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
