//auth module
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setAuth";
export const SET_ERROR = "setError";
export const SET_USER = "setUser";
export const SET_USER_PERMISSIONS="setUserPermissions";

//htmclass module
export const SET_CLASSNAME_BY_POSITION = "setClassNameByPosition";

//breadcrumb
export const SET_BREADCRUMB = "setBreadcrumb";
export const ADD_BREADCRUMB = "addBreadcrumb";

//department module

export const SET_DEPARTMENTS = "setDepartments";

//designation module
export const SET_DESIGNATIONS = "setDesignation";

//employees module
export const SET_EMPLOYEES = "setEmployees";
export const SET_ACTIVE_EMPLOYEE = "setActiveEmployee";

//employee contract moudle
export const SET_EMPLOYEE_CONTRACTS = "setEmployeeContracts";

//shift type moudle
export const SET_SHIFT_TYPES = "setShiftTypes";
export const APPEND_SHIFT_TYPES = "appendShiftType";
export const SET_SHIFT_TYPE_ID = "setShiftTypeId";
export const SET_SHIFT_TYPE_EDIT_DETAIL = "setShiftTypeEditDetail";

//attendance module
export const SET_ATTENDANCE = "setAttendance";
export const SET_TODAY_ATTENDANCE = "setTodayAttendance";
export const SET_ALL_ATTENDANCE_SEARCH_POPUP_BOX="setAllAttendanceSearchPopupBox";
export const SET_ATTENDANCE_BY_DEPARTMENT =  "setAttendanceByDepartment";
export const SET_ALL_ATTENDANCE_DETAIL_POPUP_BOX ="setAllAttendanceDetailPopupBox";
export const SET_ALL_ATTENDANCE_SEARCH_DEPARTMENT ="setAllAttendanceSerachDepartment";
export const SET_ALL_ATTENDANCE_SEARCH_YEAR ="setAllAttendanceSerachYear";
export const SET_ALL_ATTENDANCE_SEARCH_MONTH ="setAllAttendanceSerachMonth";
//common module 
export const SET_MONTHS = "setMonths";
export const SET_YEARS = "setYears";


//leaves
export const SET_EMPLOYEE_LEAVES = "setEmployeeLeaves";
export const SET_ACTIVE_LEAVE_TYPE = "setActiveLeaveType";
export const SET_EMPLOYEE_LEAVE_DETAILS= "setEmployeeLeaveDetails";
export const SET_LEAVES = "setLeaves";
//shift
export const SET_ASSIGN_SHIFT_LIST = "setAssignShiftList";
// export const ASSIGN_SHIFT_TO_USER="assignShiftToUser";


export const SET_APP_MENU = "setAppMenu";


//payroll - salaryheads
export const GET_EMPLOYEE_SALARY_HEADS = "getEmployeeSalaryHeads";
export const GET_EMPLOYEE_SALARY_HEADS_DETAILS = "getEmployeeSalaryHeadsDetails";