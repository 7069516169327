import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

// action types
import {
  FETCH_DESIGNATIONS,
  FETCH_DESIGNATIONS_BY_DEPARTMENT_ID
} from "../actions.type";
// mutation types
import { SET_DESIGNATIONS } from "../mutations.type";

const state = {
  errors: null,
  designations: []
};

const getters = {
  getDesignation(state) {
    return state.designations;
  }
};

const actions = {
  async [FETCH_DESIGNATIONS](context) {
    // avoid extronuous network call if article exists
    await ApiService.get("designations")
      .then(({ data }) => {
        context.commit(SET_DESIGNATIONS, data.data);
      });
  },

  async [FETCH_DESIGNATIONS_BY_DEPARTMENT_ID](context, id) {
    // avoid extronuous network call if article exists
    await ApiService.get("get-designation-by-department/" + id)
      .then(({ data }) => {
        context.commit(SET_DESIGNATIONS, data.data);
      });
  }
};

const mutations = {
  [SET_DESIGNATIONS](state, data) {
    state.designations = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
