export default [
    {
        path: "/employees/list",
        name: "employees.list",
        component: () => import("@/pages/admin/employee/index.vue"),
        meta: { requiresAuth: true,permission:'access_to_all_employees_profile_data' }
    },
    {
        path: "/employee/add",
        name: "employee.add",
        component: () => import("@/pages/admin/employee/add.vue"),
        meta: { requiresAuth: true,permission:'add_employee_new_contract' }
    },
    {
        path: "/employee/:id",
        component: () => import("@/pages/admin/employee/editwizard.vue"),
        meta: { requiresAuth: true,permission:'' },
        children: [
            {
                path: "/",
                name: "employee.edit.personal",
                component: () => import("@/pages/admin/employee/edit/personal.vue"),
                meta: { requiresAuth: true,permission:'update_employee_profile' }
            },
            {
                path: "documents",
                name: "employee.edit.document",
                component: () => import("@/pages/admin/employee/edit/documents.vue"),
                meta: { requiresAuth: true,permission:'' }
            },
            {
                path: "contract",
                name: "employee.edit.contract",
                component: () => import("@/pages/admin/employee/edit/contract.vue"),
                meta: { requiresAuth: true,permission:'add_employee_new_contract' }
            }
        ]
    }
]