import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
  CHECK_AUTH,
  LOGIN,
  LOGOUT,
  REGISTER,
  UPDATE_USER,FETCH_USER_PERMISSIONS
} from "../actions.type";
// mutation types
import { PURGE_AUTH, SET_AUTH, SET_ERROR, SET_USER ,SET_USER_PERMISSIONS} from "../mutations.type";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  token: {},
  permissions:[],
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getUserPermission(state){
    return state.permissions;
  }
};

const actions = {
  [FETCH_USER_PERMISSIONS](context) {
  
    return new Promise((resolve, reject) => {
      ApiService.get("user/has/permissions")
        .then(({ data }) => {
          context.commit(SET_USER_PERMISSIONS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          reject(response.data.errors);
        });
    });
  },
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("login", {
        email: credentials.email,
        password: credentials.password
      })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("users", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [CHECK_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("user")
        .then(({ data }) => {
          context.commit(SET_USER, data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  }
};

const mutations = {
  [SET_USER_PERMISSIONS](state,data){
    state.permissions =  data;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, token) {
    state.isAuthenticated = true;
    state.token = token;
    state.errors = {};
    JwtService.saveToken(state.token.access_token);
  },
  [SET_USER](state, user) {
    state.user = user;
  },

  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
