import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
    FETCH_ASSIGN_SHIFT_LIST,
    ASSIGN_SHIFT_TO_USER
} from "../actions.type";
// mutation types

import {
    SET_ASSIGN_SHIFT_LIST
} from "../mutations.type";

const state = {
  errors: null,
assignShiftList:[]
};

const getters = {
  getAssignShiftList(state) {
    return state.assignShiftList;
  },
 
};

const actions = {
  async [FETCH_ASSIGN_SHIFT_LIST](context,searchData) {
    // avoid extronuous network call if article exists
    if (JwtService.getToken()) {
      return await new Promise((resolve, reject) => {
        ApiService.post("shift/assign/list",{
          shift_id:searchData.shift_id,
          year:searchData.year,
          month:searchData.month
        })
          .then(({ data }) => {
            context.commit(SET_ASSIGN_SHIFT_LIST, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            reject(response.data);
            //   context.commit(SET_ERROR, response.data.errors);
          });
      });
    }
  },

 
  [ASSIGN_SHIFT_TO_USER](context, formData) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("shift/assign", {
          user_id: formData.user_id,
          start: formData.start,
          end: formData.end,
          shift_type_id: formData.shift_type_id
        })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  }
};

const mutations = {
  [SET_ASSIGN_SHIFT_LIST](state, data) {
    state.assignShiftList = data;
  },
 
};

export default {
  state,
  actions,
  mutations,
  getters
};
