import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

// action types
import { FETCH_DEPARTMENTS } from "../actions.type";
// mutation types
import { SET_DEPARTMENTS } from "../mutations.type";

const state = {
  errors: null,
  departments: []
};

const getters = {
  getDepartments(state) {
    return state.departments;
  }
};

const actions = {
  async [FETCH_DEPARTMENTS](context) {
    // avoid extronuous network call if article exists
    await ApiService.get("departments")
      .then(({ data }) => {
        context.commit(SET_DEPARTMENTS, data.data);
      });
  }
};

const mutations = {
  [SET_DEPARTMENTS](state, data) {
    state.departments = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
