export default [
    {
        path: "attendance",
        name: "employee.attendance",
        component: () => import("@/pages/attendance/employee/index.vue"),
        meta: { requiresAuth: true },
        children: [
            {
                path: "statements/:year/:month",
                name: "employee.attendance.statements.month",
                component: () => import("@/pages/attendance/employee/month.vue"),
                meta: { requiresAuth: true,permission:'access_to_own_attendance_records' }
            },
        ]
    },

    {
        name: "all.attendance",
        path: "/all/attendance/:year/:month",
        component: () => import("@/pages/attendance/all/index.vue"),
        meta: { requiresAuth: true,permission:'access_to_all_employees_attendance_records' },
    },
]